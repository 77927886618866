.item {
  height: 77px;
  margin: 0 19px;
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item:active {
  opacity: .6;
}

.item_active {
  opacity: .6;
}

.left {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 18px;
}

.checkbox > img {
  width: 20px;
}

.checkbox_border {
  border: 1px solid #BFBFBF;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.info {

}

.row1 {
  display: flex;
  align-items: center;
}

.currency {
  font-family: din-bold, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #2C355C;
  min-width: 100px;
}

.usdt {
  font-size: 12px;
  color: #B8BABF;
  font-family: din-bold, sans-serif;
  font-weight: bold;
}

.loop {
  width: 59px;
  height: 22px;
  background: #E7F0FD;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #044FE0;
  margin-left: 6px;
}

.loop > img {
  height: 11px;
  margin-right: 4px;
}

.row2 {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.num {
  font-size: 12px;
  font-weight: 500;
  color: #7980A1;
}

.float_loss {
  font-size: 12px;
  font-weight: 500;
  color: #7980A1;
  margin-left: 13px;
}

.down {
  color: #D21E3B;
}

.up {
  color: #28BF91;
}

.down_bg {
  background: #D3465C;
}

.up_bg {
  background: #28BF91;
}

.right {
  width: 74px;
  height: 32px;
  border-radius: 3px;
  font-size: 15px;
  font-family: din-bold, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  line-height: 32px;
}
