.main {
  height: 100%;
}
.item {
  height: 72px;
  border-bottom: 1px solid #EEEEEE;
  padding: 0 12px;
  display: flex;
  align-items: center;
  .itemName {
    font-size: 13px;
    font-weight: bold;
    color: #14181F;
  }
  .itemDate {
    flex: 1;
    text-align: center;
    font-size: 13px;
    font-family: din-bold, sans-serif;
    font-weight: 500;
    color: #A2A2A2;
  }
  .itemAmount {
    font-size: 13px;
    font-family: din-bold, sans-serif;
    font-weight: bold;
    color: #0066ED;
  }
}
