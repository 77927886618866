.main {

}

.label {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
}

.control {
  height: 53px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EEEEEE;
  input {
    font-size: 14px;
    color: #000000;
    font-weight: bold;
    flex: 1;
    outline: none;
    border: none;
  }

  .unit {
    padding-right: 18px;
    font-size: 14px;
    font-family: din-bold, sans-serif;
    font-weight: bold;
    color: #B0B6D7;
  }

  .colLine {
    width: 1px;
    height: 14px;
    background: #C9CDDF;
  }

  .all {
    padding-left: 18px;
  }

  .right {
    display: flex;
  }
  .bar{
    width: 33px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon{
    width: 15px;
    height: 17px;
  }
}
