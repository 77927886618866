.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabContainer{
  height: 100%;
}
.tabContainer .item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 18px;
}
.item .title{
  font-size: 14px;
  font-weight: 500;
  color: #2C355C;
}
.title span{
  color: #7980A1;
}
.item .chose1,.item .chose2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item .chose1{
  margin-left: auto;
  margin-right: 25px;
}

.chose1 span,.chose2 span{
  margin-left: 8px;
}
.item input{
  border: none;
  text-align: right;
  font-size: 15px;
  caret-color: #B9BCC9;
}
input::placeholder{
  color: #B9BCC9;
}

.desc{
  height: 36px;
  background: #F5F5FB;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #7980A1;
  font-size: 12px;
}
.btn1{
  margin-top: 55px;
  padding: 0 26px;
}

.box{
  padding: 27px 16px 19px;
  border-bottom: 1px solid #EEEEEE;
}
.box:last-of-type{
  border: 0;
}
.boxhead{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxhead_name{
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2C355C;
}
.boxhead_name span{
  font-size: 14px;
  font-weight: bold;
  color: #7980A1;
}
.boxhead img{
  width: 43px ;
  height: 18px;
}
.box .flexblock{
  display: flex;
}
.box .block{
  margin-top: 23px;
  display: flex;
  flex: 1;
  align-items: center;
}
.block span:first-child{
  font-size: 12px;
  font-weight: 500;
  color: #7980A1;
}
.block span:last-child{
  margin-left: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #044FE0;
}
