.problemInfo{
  height: 100%;
  overflow: scroll;
  width: 100%;
  padding: 20px;
  .title{
    color: #2C355C;
    font-size: 16px;
    font-weight: bold;
  }
  .time{
   color: #A9A9A9;
    font-size: 13px;
    margin-top: 11px;
    font-weight: 500;
  }
  .content{
    width: 100%;
    overflow: auto;
    img{
      width: 100%;
    }
  }
}
