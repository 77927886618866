.myProduct {
  background: #F5F5FB;
  height: 100%;
  .productItem {
    margin: 17px 13px 0 13px;
    height: 250px;
    background-image: linear-gradient(#def4fe, #fff);
    padding: 17px 10px;
    box-sizing: border-box;
    .productsName {
      display: flex;
      align-items: center;
      color: #131313;
      font-size: 14px;
      font-weight: 800;
      position: relative;
      margin-bottom: 16px;
      border-radius: 9px;
      img {
        width: 25px;
        height: 25px;
        margin-right: 6px;
        transform: scale(1.2);
      }
    }

    .productsQuota {
      height: 70px;
      display: flex;
      flex-direction: column;
      font-size: 32px;
      color: #0066ED;
      font-family: DIN;
      font-weight: bold;
      align-items: center;
      justify-content: center;
      span {
        color: #131313;
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .productsProfit {
      background: url("../imgs/su2.png") no-repeat;
      background-size: 1px 36px;
      background-position:  center;
      .productsPrice {
        color: #0066ED;
        font-size: 16px;
        font-weight: 500;
        font-family: DIN;
        display: flex;
        justify-content: space-between;
        span {
          color: #131C28;
          font-size: 11px;
          b {
            font-size: 20px;
            font-weight: 8500;
            margin-right: 4px;
          }
        }
      }
      .productsTips {
        color: #A2A2A2;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
      }
    }

  }
}
