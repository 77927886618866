.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabContainer{
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.tabContainer .item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 18px;
}
.item .title{
  font-size: 14px;
  font-weight: 500;
  color: #2C355C;
  width: 140px;
}
.title span{
  color: #7980A1;
}
.item .chose1,.item .chose2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item .chose1{
  /*margin-left: auto;*/
  /*margin-right: 25px;*/
}

.chose1 span,.chose2 span{
  margin-left: 8px;
}
.item input{
  border: none;
  font-size: 15px;
  caret-color: #B9BCC9;
}
input::placeholder{
  color: #B9BCC9;
}

.box{
  padding: 27px 16px 19px;
  border-bottom: 1px solid #EEEEEE;
}
.boxhead{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxhead_name{
  font-size: 14px;
  font-weight: bold;
  color: #2C355C;
}
.boxhead img{
  width: 13px ;
  height: 13px;
}
.boxhead .edit{
  margin-left: auto;
  margin-right: 23px;
}
.box .flexblock{
  display: flex;
}
.box .block{
  margin-top: 23px;
  display: flex;
  align-items: center;
  width: 0;
}
.block label{
  font-size: 12px;
  font-weight: 500;
  color: #7980A1;
  white-space: nowrap;
}
.block input{
  font-size: 13px;
  font-weight: bold;
  color: #044FE0;
  margin: 0 6px 0 6px;
  border: none;
  min-width: 74px;
  border-bottom: 1px solid #C2C5D1;
  height: 20px;
  outline: none;
}
.btns{
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.btn {
  width: 145px;
  height: 46px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 6px;
}

.left_btn {
  composes: btn;
  background: #044FE0;
}

.right_btn {
  composes: btn;
  background: #57BFAD;
}

.unit {
  font-size: 13px;
  font-weight: bold;
  color: #044FE0;
  white-space: nowrap;
}

.row {
  flex: 1;
  display: flex;
}

.col {
  flex: 1;
  display: flex;
}

.col:first-child {
  flex: 1.2;
}

.box:last-child {
  border: none;
}

.selected {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.inp {
  flex: 1;
}

.inp > input {
  width: 100%;
}
