.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabContainer{
  height: 100%;
}

.tabContainer > form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabContainer .item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 18px;
}
.item .title{
  font-size: 14px;
  font-weight: 500;
  color: #2C355C;
  width: 140px;
}
.title span{
  color: #7980A1;
}
.item .chose1,.item .chose2{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item .chose1{
  /*margin-left: auto;*/
  /*margin-right: 25px;*/
}

.chose1 span,.chose2 span{
  margin-left: 8px;
}
.item input{
  border: none;
  font-size: 15px;
  caret-color: #B9BCC9;
}
input::placeholder{
  color: #B9BCC9;
}

.desc{
  height: 36px;
  background: #F5F5FB;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #7980A1;
  font-size: 12px;
}
.btn1{
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.confirm_btn {
  width: 320px;
  height: 49px;
  background: #044FE0;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}

.inp {
  flex: 1;
}

.inp > input {
  width: 100%;
}

.selected {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.form_content {
  flex: 1;
  overflow: auto;
}
