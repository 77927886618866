.pulldown_wrapper {
  position: absolute;
  width: 100%;
  padding: 20px;
  transform: translateY(-100%) translateZ(0);
  text-align: center;
  color: #999;
}

.pullup_tips {
  padding: 20px;
  text-align: center;
  color: #999;
}
