.progressBar{
  .progressMain {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background: #EEEEEE;
    position: relative;

    .proress {
      height: 4px;
      border-radius: 2px;
      background: #0066ED;
      left: 0;
    }
  }
  .num{
    display: flex;
    color: #131C28;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-between;
    margin-top: 10px;
    span{
      color: #0066ED;
    }
  }
}

