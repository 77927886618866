:global{
  .web {
    .am-modal{
      width: 290px;
    }
    .am-modal-content{
      width: 290px;
      position: relative;
      background: #1B273F !important;
      border: 0;
      border-radius: 10px;
      background-clip: padding-box;
      padding: 0;
      .am-modal-header{
        padding: 0;
      }
      .am-modal-body{
        padding: 0 20px !important;
        font-size: 0 !important;
        line-height: 20px !important;
        overflow: hidden;
        text-align: left;
      }
    }
    .am-modal-button-group-h .am-modal-button {
      color: #2A6AE7;
      font-size: 17px;
    }
    .am-modal-button-group-h .am-modal-button:first-child {
      color: #7286A5;
    }
  }
}

.des{
  font-size: 12px;
  font-weight: 500;
  color: #D3465C;
}
.desc{
  font-size: 15px;
  font-weight: 500;
  color: #CFD2E7;
}

.p1{
  font-size: 12px;
  line-height: 1;
  margin-top: 18px;
}
.p2{
  font-size: 12px;
  line-height: 1;
  margin-top: 9px;
}
.question{
  padding-top: 18px;
  padding-bottom: 25px;
  color: #2A6AE7;
  font-size: 12px;
  text-decoration: underline;
}
