.main {
  padding-top: 25px;
}

.recharge {
  margin: 0 27px;
  background: #F4F4F4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.currency {
  margin-top: 39px;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: din-bold, sans-serif;
  text-align: center;
}

.qrcodeContainer {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .qrcode {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 137px;
  }
}

.parcel {
  width: 15px;
  height: 15px;
  border-radius: 3px 0 0 0;
  border-top: 3px solid #E0E0E0;
  border-left: 3px solid #E0E0E0;
  position: absolute;
  &.tl {
    left: 0;
    top: 0;
  }
  &.tr {
    top: 0;
    right: 0;
    transform: rotate(90deg);
  }
  &.br {
    transform: rotate(-180deg);
    bottom: 0;
    right: 0;
  }
  &.bl {
    transform: rotate(-90deg);
    left: 0;
    bottom: 0;
  }
}

.rechargeTitle {
  margin-top: 22px;
  margin-bottom: 17px;
  font-size: 13px;
  font-weight: bold;
  color: #C9CDDF;
}

.address {
  font-size: 12px;
  color: #010101;
  font-family: din-bold, sans-serif;
}

.copy {
  margin-top: 21px;
  margin-bottom: 34px;
  button {
    outline: none;
    border: none;
    background: #DBDBDB;
    border-radius: 3px;
    font-size: 13px;
    color: #96A1B8;
    width: 87px;
    height: 25px;
  }
}

.tips {
  .title {
    margin-top: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
  }
  margin: 0 15px;
  font-size: 12px;
  color: #666666;
}
