.main {
  height: 100%;
  font-size: 15px;
  color: #CFD2E7;
  padding: 0 12px 0 12px;
  line-height: 18px;
  overflow: auto;
  background: #0C1723;
  img {
    max-width: 100%;
  }

  .title {
    color: black;
    text-align: center;
  }
}
.iframe{
  border: none;
  width: 100%;
  height: 100%;
}
