.orderInfo {
  min-height: 100vh;
  height: 100vh;
  background: #F5F5FB;
  overflow: scroll;
  padding: 0 13px;
  padding-top: 17px;

  .infoMain {
    width: 100%;
    background-image: linear-gradient(#def4fe, #fff);
    padding: 17px 10px;
    box-sizing: border-box;
    margin-top: 17px;

    &:first-child {
      margin-top: 0;
    }

    .productsName {
      display: flex;
      align-items: center;
      color: #131313;
      font-size: 14px;
      font-weight: 800;
      position: relative;
      margin-bottom: 16px;

      img {
        width: 25px;
        height: 25px;
        margin-right: 6px;
      }

      .time {
        color: #A2A2A2;
        font-size: 13px;
        font-weight: 500;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .productsQuota {
      height: 70px;
      display: flex;
      flex-direction: column;
      font-size: 32px;
      color: #0066ED;
      font-family: DIN;
      font-weight: bold;
      align-items: center;
      justify-content: center;

      span {
        color: #131313;
        font-size: 12px;
        margin-top: 5px;
      }
    }

    .infoItem {
      display: flex;
      justify-content: space-between;
      height: 35px;
      align-items: center;
      color: #A2A2A2;
      font-size: 13px;
      font-weight: 500;

      span {
        color: #131C28;
        font-size: 16px;
        font-weight: 500;
        font-family: DIN;
      }

      .blue {
        color: #0066ED;
      }
    }

    .tipsTitle {
      color: #160707;
      font-size: 13px;
      font-weight: 500;
      height: 40px;
      display: flex;
      align-items: center;
    }

    .tips {
      color: #A2A2A2;
      font-size: 11px;
      line-height: 18px;
    }
  }

  .title {
    color: #333333;
    font-size: 15px;
    font-weight: 800;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .logs {
    width: 100%;
    border-radius: 5px;
    background: #fff;

    .logsItem {
      height: 60px;
      border-bottom: 1px solid #F5F5F5;
      color: #A2A2A2;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      &:last-child{
        border: 0;
      }
      .itemName {
        width: 100px;
        display: flex;
        justify-content: center;
      }

      .itemTime {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      .itemNum {
        width: 100px;
        display: flex;
        justify-content: flex-end;
        color: #0066ED;
        padding-right: 17px;
        box-sizing: border-box;
      }
    }
  }
}
