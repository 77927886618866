.container {
  height: 100%;
}

.head_bg {
  padding: 1px 18px 0 18px;
  height: 210px;
  background: #044FE0;
}

.search {
  margin-top: 18px;
  margin-bottom: 28px;
  height: 31px;
  background: #1D5EDC;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.search_img {
  margin-left: 16px;
  margin-right: 12px;
}

.search_img > img {
  width: 13px;
}

.search_inp {
  flex: 1
}

.search_inp > input {
  font-size: 14px;
  font-weight: bold;
  color: #84A9F3;
  width: 100%;
  outline: none;
  background: none;
  border: none;
}

.search_btn {
  user-select: none;
  width: 59px;
  height: 24px;
  background: #FEFEFE;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #044FE0;
  margin-right: 5px;
  text-align: center;
  line-height: 24px;
}

.search_btn:active {
  opacity: .6;
}

.assets_title {
  font-size: 12px;
  font-weight: 500;
  color: #8CAEEF;
}

.assets_row {
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.balance {
  font-size: 27px;
  font-family: din-bold, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
}

.batch_setting {
  user-select: none;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;
}

@value v-tab-height: 56px;

.tab {
  margin-top: calc(v-tab-height * -1);
  /*height: calc(100% - 210px + v-tab-height);*/
}

.tab :global(.am-tabs-horizontal .am-tabs-pane-wrap-inactive) {
  height: 100%;
}

.tab :global(.am-tabs-default-bar) {
  border-radius: 25px 25px 0 0;
}

.tab :global(.am-tabs-default-bar-tab) {
  /*border-radius: 25px 25px 0 0;*/
  padding-top: 15px;
  height: v-tab-height;
}
