.container {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.container.fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
}

.title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 19px;
  line-height: 53px;
  pointer-events: none;
}

.title.dark {
  color: white;
}

.light {
  color: #2C355C;
}

.back_btn {
  line-height: 0;
  padding: 0 17px;
}

.back_btn > img {
  height: 15px;
}
