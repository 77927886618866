.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}



.scroll {
  flex: 1;
  overflow: auto;
  padding-top: 35px;
}

.btn {
  padding: 0 13px;
  height: 68px;
  display: flex;
  align-items: center;
  button {
    outline: none;
    border: none;
    background: #0066ED;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
  }
}

