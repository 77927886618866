.container {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #2C355C;
}

.row {
  display: flex;
  /*align-items: center;*/
  height: 40px;
}

.container:first-child {
  border-top: none;
}

.col {
  flex: 1;
  display: flex;
  padding-top: 14px;
  line-height: 18px;
}

.col:last-child {
  flex: 0.7;
}

.label {
  font-size: 13px;
  font-weight: 500;
  color: #7286A5;
  white-space: nowrap;
}

.value {
  font-size: 13px;
  font-weight: bold;
  color: #CFD2E7;
  margin-left: 16px;
}
