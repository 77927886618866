.Problem{
  height: 100vh;
  background: #EBEBEB;
  overflow: scroll;
  .problemModule{
    display: flex;
    background: #fff;
    min-height: 180px;
    margin-top:5px ;
    .moduleLeft{
      flex-shrink: 0;
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .moduleIcon{
        width: 30px;
        height: 30px;
      }
      .moduleTitle{
        color: #2C355C;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        margin-top: 11px;
      }
    }
    .moduleRight{
      flex: 1;
      .problemItem{
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom:1px solid #E2E2E2 ;
        padding-left: 22px;
        padding-right: 20px;
        position: relative;
        content: #010101;
        font-size: 14px;
        &:last-child{
          border: 0;
        }
        &::after{
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background:#D0D0D0;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }
}
