.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #161F2F;
}

.head_bg {
  background: #044FE0;
  display: flex;
  flex-direction: column;
}

.ticker {
  height: 55px;
  padding-left: 22px;
  font-size: 19px;
  font-weight: bold;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

.loop {
  height: 16px;
  background: #FFFFFF;
  border-radius: 3px;
  line-height: 15px;
  font-size: 11px;
  font-weight: bold;
  color: #2C355C;
  padding: 0 7px;
  margin-left: 10px;
  margin-top: 1.5px;
}

.add_btn {
  width: 57px;
  margin-left: 3px;
}

.change_loop {
  user-select: none;
  font-size: 11px;
  font-weight: bold;
  text-decoration: underline;
  color: #FFFFFF;
  padding-left: 15px;
}

.state {
  flex: 1;
  background: #094DD0;
  padding: 10px 0;
}

.state_row {
  height: 60px;
  display: flex;
  align-items: center;
}

.state_col {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 18px;
  border-right: 1px solid #4273D2;
}

.state_col:last-child {
  border-right: none;
}

.state_value {
  font-size: 16px;
  font-family: din-bold, sans-serif;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: 12px;
}

.state_label {
  font-size: 12px;
  font-weight: 500;
  color: #8CAEEF;
  white-space: nowrap;
}

.detail {
  padding: 22px 18px 0 18px;
}

.nav {

}

.nav_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_title {
  font-size: 15px;
  font-weight: bold;
  color: #7286A5;
}

.modify_btn {
  font-size: 14px;
  font-weight: 500;
  color: #044FE0;
  display: flex;
  align-items: center;
}

.modify_btn img {
  width: 15px;
  margin-right: 4px;
}

.nav_line {
  margin-top: 7px;
  width: 16px;
  height: 2px;
  background: #7286A5;
  border-radius: 1px;
}

.strategy_list {
  padding: 20px 18px 0 18px;
}

.line {
  height: 10px;
  background: #0C1723;
}

.operation {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  justify-content: space-between;
  background: #1F2B42;
}

.content {
  height: 0;
  flex: 1;
}

.btn {
  width: 120px;
  height: 50px;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  line-height: 50px;
}

.btn:before {
  border: none !important;
}

.left_btn {
  composes: btn;
  background: #28BF91;
}

.right_btn {
  composes: btn;
  background: #42526A;
}

.right_btn:global(.am-button.am-button-disabled) {
  opacity: 0.6;
  color: white;
}

.clear_btn {
  color: #CFD2E7;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.clear_btn:active {
  opacity: .5;
}

.clear_btn > img {
  margin-bottom: 3.5px;
  width: 23px;
  height: 23px;
}

.list {
  padding: 10px 0;
}

.strategy_item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.strategy_name {
  font-size: 14px;
  font-weight: 500;
  color: #7286A5;
}

.strategy_btn {
  width: 62px;
  height: 29px;
  background: #044FE0;
  border-radius: 3px;
  line-height: 29px;
  font-size: 11px;
  font-weight: 500;
  color: #FFFFFF;
}

.strategy_btn:before {
  border: none !important;
}

.fold {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  color: #044FE0;
  padding: 13px 0;
}

.fold > img {
  margin-left: 6px;
  width: 8px;
}

.loading_text {
  flex: 1;
  color: white;
  text-align: center;
}

.hideTip {
  font-weight: 400;
  color: white;
  font-size: 14px;
  line-height: 20px;
}

.tip1 {
  background: url("./images/xq_pic.png") no-repeat center;
  background-size: cover;
  width: 339px;
  height: 161px;
  color: #CFD2E7;
  padding: 0 16px;
  overflow: hidden;
  margin: 16px auto 20px auto;
}

.tip2 {
  color: #9A9FB4;
}

.tip2Title {
  display: flex;
  align-items: center;
}

.tip2Content > p {
  margin: 0;
}

.tip2 img {
  margin-right: 4px;
  width: 12px;
}

.tip2Content {
  margin: 13px 0;
  font-size: 12px;
}
:global(.am-popover){
  width: 150px;
  height: 150px;
}
:global(.am-popover-inner-wrapper){
  background: #1F2B42;
}
:global(.am-popover-item){
  height: 48px;
}
:global(.am-popover-arrow){
  background: #1F2B42;
}
:global(.am-popover-item-content){
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}
:global(.am-popover-item-container){
  display: flex;
  justify-content: center;
  align-items: center;
}
