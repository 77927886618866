.productInfo {
  padding: 0 13px;
  height: 100%;
  overflow: scroll;
  .infoMain {
    width: 100%;
    padding: 35px 10px;
    box-sizing: border-box;
    .productsName {
      display: flex;
      align-items: center;
      color: #131313;
      font-size: 14px;
      font-weight: 800;
      position: relative;
      margin-bottom: 40px;

      img {
        width: 25px;
        height: 25px;
        margin-right: 6px;
        transform: scale(1.2);
      }
      .productsStaus{
        color: #0066ED;
        font-size: 11px;
        font-weight: 500;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .productsRed{
        color: rgb(224, 72, 103);
      }
      .time {
        color: #A2A2A2;
        font-size: 13px;
        font-weight: 500;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }


    .infoItem {
      display: flex;
      justify-content: space-between;
      height: 35px;
      align-items: center;
      color: #A2A2A2;
      font-size: 13px;
      font-weight: 500;

      &:first-child {
        margin-top: 30px;
      }

      span {
        color: #131C28;
        font-size: 16px;
        font-weight: 500;
        font-family: DIN;
      }

      .blue {
        color: #0066ED;
      }
    }

    .buyTitle {
      color: #000000;
      font-size: 12px;
      font-weight: 800;
      margin-top: 20px;
    }

    .numInput {
      height: 53px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;

      input {
        flex: 1;
        border: 0;

        &::placeholder {
          color: #A2A2A2;
          font-size: 13px;
          font-weight: 800;
        }
      }

      span {
        color: #0066ED;
        font-weight: 800;
        font-size: 13px;
      }
    }

    .balance {
      color: #A2A2A2;
      font-size: 12px;
      font-weight: 800;
      height: 40px;
      display: flex;
      align-items: center;
      span {
        margin-left: 5px;
        font-family: DIN;
      }
    }

    .tipsTitle {
      color: #160707;
      font-size: 13px;
      font-weight: 500;
      height: 40px;
      display: flex;
      align-items: center;
    }

    .tips {
      color: #A2A2A2;
      font-size: 11px;
      line-height: 18px;
    }
    .agreement{
      height: 55px;
      display: flex;
      align-items: center;
      color: #160707;
      font-size: 11px;
      font-weight: 500;
      :global(.icon){
        color:#0066ED ;
        margin-right: 6px;
        font-weight: 800;
      }
      span{
        color: #0066ED;
      }
    }
    .btn{
      height: 40px;
      background: #0066ED;
      border-radius: 6px;
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

}

:global{
  .myModal {
    width: 300px;
    .am-modal-content{
      padding: 0;
      .am-modal-body{
        padding: 0;
      }
    }
  }
}
.modal{
  .modalTitle{
    color: #20232A;
    font-size: 16px;
    font-weight: 800;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalTips{
    color: #20232A;
    font-size: 13px;
    font-weight: 800;
    line-height: 30px;
    white-space: nowrap;
    padding-bottom: 10px;
  }
  input{
    border: 0;
    height: 50px;
    border-bottom:1px solid #ccc ;
    margin: 10px;
  }
}
