.main{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img{
  width: 213px;
  height: 136px;
}
.desc{
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #B0B6D7;
}
