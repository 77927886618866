.huakuai {
  width: 20px;
  height: 27px;
  position: relative;
  top: -9.5px;
  left: -5px;
}

.dumpsPositions {
  .content {
    text-align: left;
    width: 100%;
    height: 480px;
    background: #161F2F;
    position: absolute;
    bottom: 0;
    padding: 35px 17px;
    box-sizing: border-box;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    .cion {
      display: flex;
      justify-content: space-between;
    }
    .slider{
      height: 100px;
      display: flex;
      align-items: center;
      padding: 0 40px;
    }
    .inputBox {
      .inputTittle {
        color: #8F9EAB;
        margin-top: 28px;
        display: flex;
        justify-content: flex-start;
      }
      .input {
        margin-top: 14px;
        input {
          width: 100%;
          height: 49px;
          background: #1F2B42;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 800;
          outline: none;
          border: 0;
          padding: 0 13px;
          color: #fff;
          &::placeholder{
            color: #8F9EAB;
            font-size: 14px;
          }
        }
      }
    }
    .tips{
      color: #8F9EAB;
      font-size: 12px;
      margin-top: 25px;
    }
    .btns{
      width: 100%;
      height: 83px;
      background: #1F2B42;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      .btn{
        width: 340px;
        margin: 0 auto;
        height: 44px;
        background: #EA6D22;
        border-radius: 6px;
        font-size: 16px;
        color: #fff;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
      }
    }
  }
}

