.leasingTreasure{
  .assets{
    height:275px;
    background: url("../imgs/indexbg.png") no-repeat;
    background-size: 100%;
    .assetsText{
      padding: 0 13px;
      height: 40px;
      color: #BFBFBF;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .assetsDetail{
        color: #0066ED;
        font-size: 12px;
      }
    }
    .assetsMoney{
      padding: 0 13px;
      font-size: 38px;
      color: #fff;
      height: 66px;
      display: flex;
      align-items: flex-end;
      font-family: DIN Light;
      .rmb{
        color: #BFBFBF;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .assetsMain{
      display: flex;
      height: 91px;
      align-items: center;
      .assetsItem{
        display: flex;
        flex-direction: column;
        width: calc(100% /3);
        align-items: center;
        background: url("../imgs/su.png") no-repeat;
        background-size: 1px 20px;
        background-position: right center;
        &:last-child{
          background: none;
        }
        .assetsName {
          color: #FFFFFF;
          font-size: 12px;
          font-weight: 500;
        }
        .assetsNum{
          color: #0066ED;
          font-size: 17px;
          font-weight: 800;
          margin-top: 15px;
          font-family: DIN Alternate;
        }
      }

    }
    .btns{
      padding: 0 13px;
      height: 33px;
      display: flex;
      justify-content: space-between;
      .btnItem{
        width: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 33px;
        border-radius: 4px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .products{
    background:#F5F5FB;
    border-radius: 13px 13px 0 0 ;
    position: relative;
    top: -25px;
    height: calc(100vh - 275px + 25px);
    display: flex;
    flex-direction: column;
    .productTitle{
      padding: 0 13px;
      height: 56px;
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 15px;
      font-weight: 800;
      border-radius: 13px 13px 0 0 ;
      border-bottom: 1px solid #eee;
    }
    .productsMain{
      flex: 1;
      height: 0;
      .productItem{
        height: 250px;
        background-image: linear-gradient(#def4fe, #fff);
        margin: 18px 13px 0 13px;
        padding: 17px 10px;
        box-sizing: border-box;
        border-radius: 9px;
        .productsName{
          display: flex;
          align-items: center;
          color: #131313;
          font-size: 14px;
          font-weight: 800;
          position: relative;
          margin-bottom: 16px;
          img{
            width: 25px;
            height: 25px;
            margin-right: 6px;
            transform: scale(1.2);
          }
          .productsStaus{
            color: #0066ED;
            font-size: 11px;
            font-weight: 500;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          .productsRed{
            color: rgb(224, 72, 103);
          }
        }
        .productsPrice{
          color: #0066ED;
          font-size: 18px;
          font-weight: 500;
          font-family: DIN;
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          .productsCycle{
            color: #131C28;
            font-size: 11px;
            b{
              font-size: 20px;
              font-weight: 8500;
              margin-right: 4px;
            }
          }
        }
        .productsTips{
          color: #A2A2A2;
          font-size: 13px;
          font-weight: 500;
          display: flex;
          justify-content: space-between;
          margin-top: 13px;
          .productsTime{
            color: #000000;
            font-size: 13px;
          }
        }
      }
    }
  }
}
