.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, .6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.container.show {
  transform: translateX(0);
}

.container.hide {
  transform: translateX(100%);
}

.content {
  transition: .3s transform,opacity ease-in;
}

.content.show {
  transform: scale(1);
  opacity: 1;
}

.content.hide {
  transform: scale(.7);
  opacity: .5;
}
