.container {
  background: white;
  transition: .3s transform ease-in;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.container.show {
  transform: translateY(0);
}

.container.hide {
  transform: translateY(100%);
}
