.main {
  height: 100%;
  font-size: 11px;
  color: #A2A2A2;
  padding: 0 12px 0 12px;
  line-height: 18px;
  overflow: auto;
  img {
    max-width: 100%;
  }

  .title {
    color: black;
    text-align: center;
  }
}
