.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #9A9FB4;
  margin-top: 31px;
}

.container img {
  transform: scale(0.4);
}
