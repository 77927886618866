@import-normalize;
@import "https://at.alicdn.com/t/font_2438678_fjexaxdxtbq.css";
@font-face {
  font-family: din-bold;
  src: url(./assets/fonts/DIN-Bold.otf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    PingFang SC, Hiragino Sans GB, Microsoft YaHei
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
  background: white;
}

.animation-container > div {
  height: 100%;
  overflow: hidden;
}

.fade-enter {
  opacity: 0;
  transform: translateY(30px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1),opacity 200ms ease-in;
}

/*.fade-exit {
  opacity: 1;
  transform: translateY(0);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translateY(30px);
  transition: transform 200ms cubic-bezier(0.645, 0.045, 0.355, 1),opacity 200ms ease-in;
}*/

* {
  box-sizing: border-box;
}

button {
  border: none;
  background: none;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  PingFang SC, Hiragino Sans GB, Microsoft YaHei
  sans-serif !important;
}

button:active {
  opacity: .6;
}
