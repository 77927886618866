.main{
  height: 90px;
}
.item{
  width: 100%;
  height: 80px;
  padding: 21px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.divBg{
  height: 10px;
  background: #F9F9F9;
}
.right{
  font-size: 14px;
  font-weight: 500;
  color: #B0B6D7;
  width: 10%;
  white-space: nowrap;
}
.left{
  width: 90%;
  .remark{
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    span{
      display: inline-block;
      padding: 4px;
      background: #EAF3FF;
      border-radius: 2px;
      color: #0066ED;
      font-size: 11px;
      margin-left: 6px;
    }
  }
  .value{
    font-size: 14px;
    font-weight: 500;
    color: #A2A2A2;
    margin-top: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.img{
  width: 45px;
  height: 45px;
  margin-right: 14px;
}
