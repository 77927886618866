.container {
  width: 290px;
  background: #1B273F;
  border-radius: 10px;
}

.title {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: #CFD2E7;
}

.row_inp {
  height: 55px;
  display: flex;
  padding-top: 8px;
  align-items: center;
  border-bottom: 1px solid #2C355C;
  margin: 0 16px;
}

.label {
  width: 80px;
  font-size: 15px;
  font-weight: 500;
  color: #7286A5;
}

.inp {
  flex: 1;
}

.inp > input {
  width: 100%;
  font-size: 15px;
  /*font-weight: bold;*/
  color: #7286A5;
  outline: none;
  border: none;
  background: none;
}

.tip {
  margin: 0 16px;
  height: 40px;
}

.tip_text {
  margin-top: 18px;
  font-size: 13px;
  font-weight: 500;
  color: #7980A1;
}

.tip_text > span {
  font-size: 15px;
  font-weight: 500;
  color: #F12C44;
  vertical-align: sub;
}

.operation {
  height: 43px;
  border-top: 1px solid #2C355C;
}

.btn {
  width: 50%;
  height: 100%;
  line-height: 43px;
}

.cancel_btn {
  composes: btn;
  font-size: 17px;
  font-weight: 500;
  color: #7286A5;
  border-right: 1px solid #2C355C;
}

.confirm_btn {
  composes: btn;
  font-size: 17px;
  font-weight: 500;
  color: #2A6AE7;
}

.state_info {
  margin: 0 16px;
}

.state_info_item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.value {
  color: white;
  font-size: 15px;
}

.state_label {
  width: 120px;
  font-size: 15px;
  font-weight: 500;
  color: #7286A5;
}
