.modal_nav {
  padding: 0 18px;
  /*text-align: center;*/
  font-size: 15px;
  font-weight: bold;
  color: #7980A1;
  background: #F5F5FB;
  height: 47px;
  line-height: 47px;
  position: relative;
}

.modal_nav > img {
  width: 13px;
  position: absolute;
  right: 19px;
  top: 17px;
}

.modal_container {
  padding: 35px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modal_item {
  height: 90px;
  width: 74px;
  background: #F6F6F6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon > img {
  width: 30px;
}

.modal_item:active {
  opacity: .6;
}

.name {
  font-size: 12px;
  font-weight: bold;
  color: #7980A1;
}

.checkbox {
  display: flex;
  align-items: center;
}

.checkbox > img {
  width: 20px;
  margin-right: 7px;
}
