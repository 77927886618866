.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.textField {
  padding: 0 13px;
  margin-top: 35px;
}

.currency {
  font-size: 14px;
  font-family: din-bold, sans-serif;
  font-weight: bold;
  color: #0066ED;
  margin-top: 22px;
  padding: 0 13px;
}

.available {
  margin-top: 12px;
  padding: 0 13px;
  font-size: 12px;
  font-family: din-bold, sans-serif;
  font-weight: bold;
  color: #B0B6D7;
}

.tips {
  background: #F4F4F4;
  border-radius: 10px;
  margin: 33px 13px 0 13px;
  padding: 4px 16px;
  font-size: 12px;
  color: #96A1B8;
}

.scroll {
  flex: 1;
  overflow: auto;
}

.footer {
  height: 85px;
  border-top: 1px solid #EEE;
  padding: 0 15px;
  overflow: hidden;
}

.real {
  font-size: 12px;
  color: #B0B6D7;
  font-weight: bold;
  font-family: din-bold, sans-serif;
  margin: 10px 0;
}

.submit {
  height: 40px;
  display: flex;
  .inp {
    flex: 1;
    height: 100%;
    background: #F4F4F4;
    border-radius: 5px;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      font-size: 14px;
      color: #000;
      text-align: center;
      outline: none;
      border: none;
      background: none;
    }
  }
  .btn {
    margin-left: 13px;
    button {
      outline: none;
      border: none;
      background: #0066ED;
      width: 138px;
      height: 40px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}

.initButton {
  outline: none;
  border: none;
  background: none;
}

.dialog {
  width: 252px;
  height: 146px;
  background: white;
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dialogTitle {
    font-size: 16px;
    font-weight: bold;
    color: #20232A;
    margin-top: 21px;
  }
  .dialogContent {
    font-size: 13px;
    font-weight: bold;
    color: #20232A;
    line-height: 30px;
  }
  .dialogBtn {
    @extend .initButton;
    font-size: 15px;
    font-weight: bold;
    color: #0066ED;
    line-height: 42px;
    height: 50px;
    width: 100%;
  }
}
